import React from 'react';
import LinkIconButton from './components/LinkIconButton.js';
import LinkTextButton from './components/LinkTextButton.js';
import './App.css';
import BigIcon from './components/BigIcon.js';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      windowWidth: 0,
      windowHeight: 0
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions() {
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;

    this.setState({ windowWidth, windowHeight });
  }

  render() {

    const { windowWidth, windowHeight } = this.state

    const widthPivot = 512 + 16
    const widthPivot2 = 256 + 16
    const heightPivot = 512 + 16 + 48 + 48
    const heightPivot2 = 256 + 16 + 48 + 48

    var padding
    var iconSize
    var linkFontSize
    if (windowHeight > heightPivot && windowWidth > widthPivot) {
      iconSize = "512"
      padding = 12
      linkFontSize = 32
    } else if (windowHeight > heightPivot2 && windowWidth > widthPivot2) {
      iconSize = "256"
      padding = 8
      linkFontSize = 24
    } else {
      iconSize = "128"
      padding = 4
      linkFontSize = 16
    }

    const mainColumnStyle = {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    }

    const centerRow = {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    }

    const aStyle = {
      color: "violet",
      fontSize: linkFontSize,
      padding: padding,
      fontFamily: 'ThinKing',
      letterSpacing: 1,
      textDecorationLine: 'none',
      fontWeight: "600"
    }

    return (
      <div style={mainColumnStyle}>
        <div style={centerRow}>
          <BigIcon icon={require('./resources/logos/logo' + iconSize + '.png')} padding={padding} />
        </div>
        <div style={centerRow}>
          <LinkTextButton nonHoverText="bandcamp" hoverText="bandcamp" link='http://copingmechanics.bandcamp.com' padding={padding} newpage="true" fontSize={linkFontSize} fontColor="#1C96BB" />
          <LinkTextButton nonHoverText="soundcloud" hoverText="soundcloud" link='http://www.soundcloud.com/copingmechanics' padding={padding} newpage="true" fontSize={linkFontSize} fontColor="#FF4B00" />
          <LinkTextButton nonHoverText="youtube" hoverText="youtube" link='https://www.youtube.com/@CopingMechanicsBand' padding={padding} newpage="true" fontSize={linkFontSize} fontColor="#FF0000" />
        </div>
        <div style={centerRow}>
          <LinkTextButton nonHoverText="instagram" hoverText="instagram" link='http://www.instagram.com/copingmechanics' padding={padding} newpage="true" fontSize={linkFontSize} fontColor="#FF0069" />
          <LinkTextButton nonHoverText="facebook" hoverText="facebook" link='http://www.facebook.com/copingmechanics' padding={padding} newpage="true" fontSize={linkFontSize} fontColor="#085BFE" />
          <LinkTextButton nonHoverText="twitter" hoverText="twitter" link='http://www.twitter.com/copingmechsband' padding={padding} newpage="true" fontSize={linkFontSize} fontColor="#1A93EE" />
        </div>
        <div style={centerRow}>
          <a target="_blank" rel="noopener noreferrer" style={aStyle} href="mailto:mechanicscoping@gmail.com">mechanicscoping@gmail.com</a>
        </div>
      </div>);
  }
}

export default App;
