import React, { useState } from 'react';

function LinkIconButton({ nonHoverText, hoverText, link, newpage, padding, fontSize, fontColor }) {

    const [text, setText] = useState(nonHoverText)

    var style = {
        color: "white",
        fontSize: fontSize,
        fontFamily: 'ThinKing',
        letterSpacing: 1,
        textDecorationLine: 'none',
        color: fontColor,
        fontWeight: "600"
    }

    var padding = {
        padding: padding,
    }

    var target = "_self"
    if (newpage) {
        target = "_blank"
    }

    return (
        <div style={padding} >
            <a style={style} href={link} target={target} rel="noopener noreferrer"
                onMouseLeave={() => setText(nonHoverText)}
                onMouseEnter={() => setText(hoverText)}>
                {text}
            </a>
        </div>
    )
}

export default LinkIconButton