import React from 'react';

function BigIcon({ icon, padding }) {

    var style = {
        padding: padding
    }

    return (
        <div style={style}>
            <img src={icon} alt="nothing" />
        </div>
    )
}

export default BigIcon